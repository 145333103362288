import Keycloak from 'keycloak-js'; // import Keycloak library
 
// Initialize Keycloak instance
const keycloak = new Keycloak({
  url: 'https://uldevops.ulbazaar.com/keycloak/',
  realm: 'devopsplatform',
  clientId: 'homepage',
 
});
 
export default keycloak;
