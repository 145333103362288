import React from 'react';
import logo1 from '../../assets/logo11.png';
import logo2 from '../../assets/logo21.png';
import logo3 from '../../assets/logo31.png';
import logo4 from '../../assets/logo4.png';
import logo5 from '../../assets/ldap.png';
import './Tools.css';

export const AdminTools = () => {
  const handleItemClick = (url) => {
    window.open(url, '_blank');
  };

  return (

    <div>
      <div className='heading'>DevOps Tools</div>
    <div className='tools'>
      <div className='tool-item'>
        <div className='logo-and-text-container'>
          <img src={logo1} alt='Logo 1' className='circle-icon' />
          <div className='toolsText'>
            <h2 onClick={() => handleItemClick('https://uldevops.ulbazaar.com/gitlab/')}>Gitlab</h2>
          </div>
        </div>
        <div className='line-divider'></div>
        <p>an open-core company that operates GitLab, a DevOps software package that can develop, secure, and operate software</p>
      </div>

      <div className='tool-item'>
        <div className='logo-and-text-container'>
          <img src={logo2} alt='Logo 2' className='circle-icon' />
          <div className='toolsText'>
            <h2 onClick={() => handleItemClick('https://uldevops.ulbazaar.com/jenkins/')}>Jenkins</h2>
          </div>
        </div>
        <div className='line-divider'></div>
        <p>an open source automation server. It helps automate the parts of software development related to building, testing, and deploying, facilitating continuous integration, and continuous delivery</p>
      </div>

      <div className='tool-item'>
        <div className='logo-and-text-container'>
          <img src={logo3} alt='Logo 3' className='circle-icon' />
          <div className='toolsText'>
            <h2 onClick={() => handleItemClick('https://uldevops.ulbazaar.com/sonarqube/')}>Sonarqube</h2>
          </div>
        </div>
        <div className='line-divider'></div>
        <p>an open-source platform developed by SonarSource for continuous inspection of code quality to perform automatic reviews with static analysis of code to detect bugs and code smells on 29 programming languages</p>
      </div>

      <div className='tool-item'>
        <div className='logo-and-text-container'>
          <img src={logo4} alt='Logo 4' className='circle-icon' />
          <div className='toolsText'>
            <h2 onClick={() => handleItemClick('https://uldevops.ulbazaar.com/nexus/')}>Nexus</h2>
          </div>
        </div>
        <div className='line-divider'></div>
        <p>organizes, stores and distributes artifacts needed for development</p>
      </div>

      <div className='tool-item'>
        <div className='logo-and-text-container'>
          <img src={logo5} alt='Logo 4' className='circle-icon' />
          <div className='toolsText'>
            <h2 onClick={() => handleItemClick('https://uldevops.ulbazaar.com/lam/')}>LDAP</h2>
          </div>
        </div>
        <div className='line-divider'></div>
        <p>The Lightweight Directory Access Protocol is an open, vendor-neutral, industry standard application protocol for accessing and maintaining distributed directory information services over an Internet Protocol network</p>
      </div>
      
      
    </div>
    </div>
  );
}
