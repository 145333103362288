import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavBar } from '../components/NavBar/NavBar';
import { Tools } from '../components/Tools/Tools';
import { useKeycloak } from '@react-keycloak/web';

export const HomePage = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login(); // Redirect to Keycloak login page if not authenticated
    }
  }, [keycloak.authenticated]);

  const handleLogout = async () => {
    try {
      await keycloak.logout();
      navigate('/login');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div>
      <NavBar handleLogout={handleLogout} isAuthenticated={true} />
      <Tools />
    </div>
  );
};
