// HomePage component
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavBar } from '../components/NavBar/NavBar';
import { AdminTools } from '../components/Tools/AdminTools';


export const AdminHome = ({ setAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is authenticated
    const isAuthenticated = localStorage.getItem('token') !== null;

    if (!isAuthenticated) {
      // Redirect to the login page if not authenticated
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    // Clear the authentication token and username from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('username');

    // Update the authentication status to false
    setAuthenticated(false);

    // Redirect to the login page after logout
    navigate('/login');
  };

  return (
    <div>
      <NavBar handleLogout={handleLogout} isAuthenticated={true} />
      <AdminTools/>
    </div>
  );
};
