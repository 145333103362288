import React, { useEffect, useState } from 'react';
import './LoginPage.css';
import AccentureLogo from '../assets/ACN_BIG.png';
import Login_BG from '../assets/devops_image.jpg';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (keycloak.authenticated && !shouldRedirect) {
      setShouldRedirect(true);
    }
  }, [keycloak.authenticated, shouldRedirect]);

  const handleKeycloakLogin = async () => {
    try {
      await keycloak.login();
    } catch (error) {
      console.error("Error in Keycloak login:", error);
    }
  };

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/home');
    }
  }, [shouldRedirect, navigate]);

  return (
    <div className="login-container">
      {/* First Navbar */}
      <div className="loginnavbar">
        <div className="logo">
          <img src={AccentureLogo} alt="Company Logo" />
          <span id="first-nav"></span>
        </div>
        <div className="brand">
          <h4>
            <span>
              <sup>®</sup>&nbsp;DevOps Tools
            </span>
          </h4>
        </div>
      </div>

      {/* Second Navbar */}
      <div className="login-second-navbar">
        <div className="brand">
          <span>
            <sup>®</sup>&nbsp;DevOps Tools
          </span>
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {/* Sign in with Keycloak */}
        <div className="login-form">
          <h2 className='logintext'>Login</h2>
          <button className='keycloak-button' onClick={handleKeycloakLogin}>Sign in with Keycloak</button>
          
         
        </div>
        <div className="background-image">
          <img src={Login_BG} alt="DevOps Engineering image" />
        </div>
      </div>

    </div>
  );
};

export { LoginPage };
