// NavBar component
import React from 'react';
import './NavBar.css';

export const NavBar = ({ handleLogout, isAuthenticated }) => {
  // Get the username from localStorage
  const username = localStorage.getItem('username');

  return (
    <div className='NavBar'>
      <div className='user'>
       
        {isAuthenticated && (
          <div className='welcome-message'>Welcome, {username}</div>
        )}

         {isAuthenticated && (
        <div className='logout'>
          <button className='logoutButton' onClick={handleLogout}>Logout</button>
        </div>
      )}
      </div>
     
       
    </div>
  );
};
