import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import { AdminHome } from './pages/AdminHome';
import { useKeycloak } from '@react-keycloak/web';

function App() {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    // Check if it's the first time the user logs in
    const isFirstLogin = localStorage.getItem('firstLogin') === null;

    if (isFirstLogin && keycloak.authenticated) {
      // If it's the first login and the user is authenticated, set the flag in local storage
      localStorage.setItem('firstLogin', 'true');
    }
  }, [keycloak.authenticated]);

  const isAuthenticated = keycloak.authenticated;
  const isUserAdmin = keycloak.authenticated && keycloak.tokenParsed?.preferred_username === 'admin';

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/home"
            element={isAuthenticated ? <HomePage /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin-home"
            element={isUserAdmin ? <AdminHome /> : <Navigate to="/login" />}
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
